

export default {
    prefixInteger: function (num, n) {
        return (Array(n).join(0) + num).slice(-n);
    },
    test1: function name() {
        console.log(321);
    }




}


