<template>
  <el-dialog title="增加无线测温仪传感器" :close-on-click-modal="false" :visible.sync="visible">
    <el-form ref="form" :model="form" :rules="rules" v-loading="formLoading" label-width="100px">
      <el-form-item label="序列号" prop="sensorNumber">
        <el-input v-model="form.sensorNumber" @blur="handleBlur"></el-input>
      </el-form-item>
      <el-form-item label="温度补偿" prop="temperatureCompensation">
        <el-input v-model.number="form.temperatureCompensation" type="number"></el-input>
      </el-form-item>
      <el-form-item label="密钥" prop="pwd">
        <el-input v-model="form.pwd" readonly></el-input>
      </el-form-item>
      <el-form-item label="允许激活" prop="status">
        <el-checkbox v-model="form.status"></el-checkbox>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="addModel">保存</el-button>
        <el-button @click="visible=false">关闭</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    var validateSensorNumber = (rule, value, callback) => {
      if (value && value.length == 16) {
        callback();
      } else {
        callback(new Error("长度必须16"));
      }
    };
    return {
      form: {
        pwd: "",
        sensorNumber: "",
        temperatureCompensation: 0.0,
        deviceType: "WXCW01",
        status: true,
      },
      visible: false,
      rules: {
        sensorNumber: [{ validator: validateSensorNumber }],
        temperatureCompensation: [{ type: "number", message: "必须数字" }],
        pwd: [{ required: true, message: "必填" }],
      },
      formLoading: false,
    };
  },
  methods: {
    add() {
      let that = this;
      that.visible = true;
      if (that.$refs.form) {
        that.$refs.form.resetFields();
      }
    },
    addModel() {
      let that = this;
      that.$refs.form.validate((valid) => {
        if (valid) {
          that.formLoading = true;
          that.axios
            .post("WXCW_WXCWSensor/AddModel", that.form)
            .then(function (response) {
              if (response.data.code == 101) {
                that.formLoading = false;
                that.visible = false;
                that.$parent.getList();
                that.$message.success("保存成功");
              } else if (response.data.code == 201) {
                that.formLoading = false;
                that.$alert(response.data.data);
              }
            });
        } else return false;
      });
    },
    handleBlur() {
      let that = this;
      that.axios
        .post("WXCW_WXCWSensor/GetPWD", {
            onlyText: that.form.sensorNumber,
        })
        .then(function (response) {
          if (response.data.code == 101) {
            that.form.pwd = response.data.data;
          } else {
            that.form.pwd = "";
          }
        });
    },
  },
};
</script>

<style>
</style>