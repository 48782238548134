<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="9">
                <el-input v-model="sensorNumber">
                    <template slot="prepend">传感器序列号</template>
                    <el-button slot="append" icon="el-icon-search" @click="getList(1)"></el-button>
                </el-input>
            </el-col>
            <el-col :span="6">
                <el-button type="primary" @click="$refs.addDialog.add()">增加</el-button>
                <el-button type="success" @click="updateStatus(true)">允许激活</el-button>
                <el-button type="danger" @click="updateStatus(false)">禁止激活</el-button>
            </el-col>
        </el-row>
        <el-table style="margin-top: 10px;" ref="elTable" :data="tableData" :row-style="rowStyle" v-loading="tableLoading" @row-dblclick="handleRowDblClick" border>
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column prop="sensorNumber" label="序列号"></el-table-column>
            <el-table-column prop="temperatureCompensation" label="温度补偿" align="right"></el-table-column>
            <el-table-column prop="pwd" label="密钥" :formatter="pwdFormatter" align="right"></el-table-column>
            <el-table-column prop="status" label="允许激活" :formatter="v => { return v.status ? '是' : '否' }"></el-table-column>
            <el-table-column prop="serialNumber" label="serialNumber_cmd_34"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="155"></el-table-column>
            <el-table-column label="操作" width="100" align="center">
                <template #default="scope">
                    <el-button type="primary" icon="el-icon-full-screen" size="mini" @click="() => { qrText = 'http://bjlcyq.cn/s?c=' + scope.row.sensorNumber; visibleQrCode = true }" circle></el-button>
                    <el-button v-if="!scope.row.pwd" type="primary" icon="el-icon-delete" size="mini" @click="del(scope.row)" circle></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="getList" @size-change="() => { getList() }" background :total="total" :page-size.sync="pageSize" :page-sizes="[10, 50, 100]" layout="sizes,prev, pager, next, jumper, ->, total" :current-page.sync="currentPage"></el-pagination>
        <add-dialog ref="addDialog"></add-dialog>
        <edit-dialog ref="editDialog"></edit-dialog>
        <el-dialog :visible.sync="visibleQrCode" width="20%">
            <div style="text-align: center;">
                <vueQr :text="qrText"></vueQr>
            </div>
            <div style="text-align: center;">
                {{ qrText }}
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "@/util/util";
import AddDialog from "./AddDialog";
import EditDialog from "./EditDialog";
import vueQr from "vue-qr";
export default {
    components: { AddDialog, EditDialog, vueQr },
    data() {
        return {
            pageSize: 10,
            currentPage: 1,
            total: 0,
            tableData: null,
            sensorNumber: "",
            qrText: "",
            tableLoading: false,
            visibleQrCode: false,
        };
    },
    methods: {
        rowStyle({ row }) {
            if (!row.pwd) {
                return { backgroundColor: "#D2B48C" };
            }

        },
        getList(cp) {
            let that = this;
            if (cp) that.currentPage = cp;
            that.tableLoading = true;
            that.axios
                .post("WXCW_WXCWSensor/GetList", {
                    sensorNumber: that.sensorNumber,
                    pageSize: that.pageSize,
                    currentPage: that.currentPage,
                    total: that.total,
                })
                .then(function (response) {
                    that.tableLoading = false;
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                });
        },
        pwdFormatter: function (row) {
            return util.prefixInteger(row.pwd, 8);
        },
        handleRowDblClick(row) {
            let that = this;
            that.$refs.editDialog.edit(row);
        },
        del(row) {
            console.log(row);
            let that = this;
            that
                .$confirm("确定要删除吗？")
                .then(function () {
                    that.axios
                        .post("WXCW_WXCWSensor/DelModel", { id: row.id })
                        .then(function () {
                            that.getList();
                        });
                })
                .catch(function () { });
        },
        updateStatus(v) {
            let that = this;
            if (that.$refs.elTable.selection.length == 0) {
                that.$alert("请选中行");
            } else {
                let ids = "";
                that.$refs.elTable.selection.forEach((element) => {
                    ids += element.id + ",";
                });
                that
                    .$confirm("确定要操作吗？")
                    .then(function () {
                        that.axios
                            .post("WXCW_WXCWSensor/UpdateStatus", { ids: ids, status: v })
                            .then(function () {
                                that.getList();
                            });
                    })
                    .catch(function () { });
            }
        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style></style>